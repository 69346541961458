require('../css/app.css');
require('../css/lightbox.css');
//require('jquery-ui.1.11.1/dist/jquery-ui');
//require('jquery-ui.1.11.1/dist/jquery-ui.css');
const $ = require('jquery');
global.$ = global.jQuery = $;
require('bootstrap');
require('@dashboardcode/bsmultiselect');
require('select2');
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/css/brands.min.css');
require('@fortawesome/fontawesome-free/js/all.js');
require('@fortawesome/fontawesome-free/js/brands.js');
const LighBox = require('./lighbox.js');

$('.custom-file-input').on('change', function () {
    let fileName = $(this).val().split("\\").pop();
    $(this).next('.custom-file-label').html(fileName);
});

$('.bsmultiselect').bsMultiSelect();

$.fn.select2.defaults.set("theme", "bootstrap4");
$.fn.select2.amd.define('select2/i18n/ru', [], function () {
    return {
        errorLoading: function () {
            return 'Результат не может быть загружен.';
        },
        inputTooLong: function (args) {
            let overChars = args.input.length - args.maximum;
            let message = 'Пожалуйста, удалите ' + overChars + ' символ';
            if (overChars >= 2 && overChars <= 4) {
                message += 'а';
            } else if (overChars >= 5) {
                message += 'ов';
            }

            return message;
        },
        inputTooShort: function (args) {
            let remainingChars = args.minimum - args.input.length;
            let message = 'Пожалуйста, введите ' + remainingChars + ' или более символов';

            return message;
        },
        loadingMore: function () {
            return 'Загружаем ещё ресурсы…';
        },
        maximumSelected: function (args) {
            let message = 'Вы можете выбрать ' + args.maximum + ' элемент';
            if (args.maximum >= 2 && args.maximum <= 4) {
                message += 'а';
            } else if (args.maximum >= 5) {
                message += 'ов';
            }

            return message;
        },
        noResults: function () {
            return 'Ничего не найдено';
        },
        searching: function () {
            return 'Поиск…';
        }
    };
});
$.fn.select2.defaults.set("language", "ru");

let banners = $('.banner');
banners.on("mouseenter", function (e) {
    let target = $(e.target);
    let url = target.attr("data-full");
    target.css('background', 'url(' + url + ') center center no-repeat');
    target.css('background-size', 'cover');
});

banners.on("mouseleave", function (e) {
    let target = $(e.target);
    //target.hide('fade');
    let url = target.attr("data-small");
    target.css('background', 'url(' + url + ') center center no-repeat');
    target.css('background-size', 'cover');
});

$('.cursor-video').on('mouseover', function (e) {
    //$('video', this).trigger('play');
    if ($('video', this).length > 0) {
        $('video', this).trigger('play');
        return;
    }
    playVideoAtContainer(this);
});

function playVideoAtContainer(container) {
    let url = $(container).attr('data-url');
    let video = $("<video width='100%' muted loop autoplay style='display: block' class='rounded'></video>");
    let img = $('img', container);
    let loading = $("<img src='/images/loading.svg' alt='' style='position:absolute;left:10px;top:10px;width:20px;height:20px;'></img>").hide();
    video.append("<source src='" + url + "'></source>");
    video.hide();
    video[0].onloadeddata = function () {
        img.hide();
        video.show();
        setTimeout(() => loading.hide('fade'), 500);
    };
    $(container).append(video);
    $(container).append(loading);
    loading.show('fade');
}

$('.cursor-video').on('mouseleave', function () {
    $('video', this).trigger('pause');
});

$('#video-close').on('click', function() {
    $('#video-container').hide();
    $('body').removeClass('stop-scrolling');
    let source = $('#video-source').get(0);
    source.pause();
});

$('#video-container').on('click', function() {
    $('#video-container').hide();
    $('body').removeClass('stop-scrolling');
    let source = $('#video-source').get(0);
    source.pause();
});

$('#video-source').on('click', (e) => e.stopPropagation());

global.openVideo = function(src) {
    $('#video-container').show();
    $('body').addClass('stop-scrolling');
    let source = $('#video-source').get(0);
    source.src = src;
    source.play();
};

let sl = new LighBox.SimpleLightbox('.gallery a[target="_self"]', {});

$(function () {

    let updateDateTime = () => {
        // timers update
        let timers = $('.auto-datetime');
        let options = {seconds: '0-digit'};
        timers.each(el => {
            let self = $(timers[el]);
            let timestamp = self.attr('data-timestamp');
            let loc = self.attr('data-locale');//+'-'+locale.toUpperCase();
            let date = new Date(timestamp * 1000);
            let content = date.toLocaleTimeString(loc) + ' ' + date.toLocaleDateString(loc, options);
            self.html(content);
        });
    };

    let updateDate = () => {
        // timers update
        let timers = $('.auto-date');
        let options = {seconds: undefined};
        timers.each(el => {
            let self = $(timers[el]);
            let timestamp = self.attr('data-timestamp');
            let loc = self.attr('data-locale');//+'-'+locale.toUpperCase();
            let date = new Date(timestamp * 1000);
            let content = date.toLocaleDateString(loc, options);
            self.html(content);
        });
    };

    setInterval(() => {
        updateDateTime();
        updateDate();
    }, 10000);
    updateDateTime();
    updateDate();

    let bannerVideos = $('.banner-video[data-autoplay="true"]');
    console.log(bannerVideos.length);
    bannerVideos.each(el => {
        playVideoAtContainer(bannerVideos[el])
    });
});
